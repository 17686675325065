<template>
  <GlobalDialog
    class="flex flex-col w-[450px] dialog avv-gray-100 avv-bg"
  >
  <div class="flex justify-end">
  <button
      type="button"
      @click="close"
    >
      <span class="material-symbols-outlined">close</span>
    </button>
  </div>
  <div class="flex flex-col items-center mb-8">
    <ASVG class="inline-block mb-2" name="CheckCircle" />
    <h2 class="text-base font-semibold">{{localize('title')}}</h2>
  </div>
  <h2 class="text-sm mb-1">{{localize('body')}}</h2>
  <ul class="mb-8">
    <li v-for="doc in props.documents_data" class="ml-4">
  <a class="text-sm" :href="doc.link" >
    <ASVG class="inline-block mr-1" name="DocGreen" />{{doc.name}}</a>
  </li>
  </ul>

   
    <!-- Buttons -->
    <div class="flex justify-between">
        <PreStyleButton
          mode="primary_border"
          @click="handleDownload"
        >
        {{localize('download')}}
        </PreStyleButton>
        <PreStyleButton
          :type="'a'"
          mode="primary_full"
          :href="documentPacksPath"
        >
        {{localize('document_packs')}}
        </PreStyleButton>
      
    </div>
  </GlobalDialog>
</template>
<script lang="ts" setup>

import GlobalDialog from '../dialogs/GlobalDialog.vue'
import ASVG from '../_abstract/ASVG.vue'
import PreStyleButton from '../_abstract/PreStyleButton.vue'
import ExportApi from '@api/Documents/ExportApi';
import DownloadDialog from '../download_dialog/DownloadDialog.vue'
import { useDialog } from '@component-utils/dialogs';
import ClipsApi from '~/api/TemplatePacks/ClipsApi';

defineOptions({ name: 'CreatedDialog'})

const props = defineProps<{
  clip_id: number,
  documents_data: {
    name: string,
    link: string, 
    id: number,
    formats: string[]
  }[]
}>()

const documentPacksPath = ClipsApi.get.path({id: props.clip_id})

const exportPdfOnlyDocument = Object.values(props.documents_data).some(doc => {
  return doc.formats.length === 1 && doc.formats.includes('document_pdf');
});

const emit = defineEmits<{
  (e: 'close'): void
}>()

//Actions

const close = () => {
  emit('close')
}

const handleDownload = () => {
  // if there are document that are restricted to pdf download only we want to make user choose if they want to download pdf of those documents
  if (!exportPdfOnlyDocument) {
    download()
  } else {
    close()

    useDialog(
      DownloadDialog,
      {
        origin: "createdDialog",
        createdDialogData: props.documents_data
      }
    )
  }
}

const download = () => {
  void window.avv_download(
    ExportApi.create.path(),
    {
    document_ids: props.documents_data.map((d) => d.id),
    formats: "document_docx"
  })

}

const localize = (key: string) =>
  window.localizeText(`template_pack.created_dialog.${key}`)

</script>
